<template>
<div>
  <b-card no-body>
    <card-list 
        :filter.sync="$parent.filter"
        :dataListing.sync="risiko"
        :idKey="$parent.idKey"
        :fields="fields"
        :no-btn-mass="true"
      > 
        <!-- <b-tr slot="thead-top">
          <b-th colspan=7 class="full-color-table full-purple-table text-center border-bot-none"></b-th>
          <b-th colspan=3 class="full-color-table full-danger-table text-center border-bot-none">INHEREN</b-th>
          <b-th colspan=2 class="full-color-table full-purple-table text-center border-bot-none">Skor Efektivitas</b-th>
          <b-th colspan=3 class="text-center full-color-table full-warning-table border-bot-none">CURRENT</b-th>
          <b-th colspan=4 class="full-color-table full-purple-table text-center border-bot-none"></b-th>
          <b-th colspan=3 class="full-color-table full-info-table text-center border-bot-none">Residual</b-th>
          <b-th colspan=3 class="full-color-table full-purple-table text-center border-bot-none"></b-th>
        </b-tr> -->
        <template #filters>
           <b-col md=4>
            <VSelect 
              v-model="$parent.filter.risiko_korporat" 
              placeholder="-- Semua Risiko Korporat --"
              :options="$parent.mrRisikoKorporat" 
              :reduce="v=>String(v.value)" 
              @input="$parent.doFilter"
            />
          </b-col>
           <b-col md=3 v-if="periode == 'bulan'">
            <VSelect 
              v-model="$parent.filter.bulan" 
              placeholder="-- Semua Bulan --"
              :options="$parent.bulan" 
              :reduce="v=>String(v.value)" 
              @input="$parent.doFilter"
            />
          </b-col>
          <b-col md=3 v-else>
            <VSelect 
              v-model="$parent.filter.triwulan" 
              placeholder="-- Semua Triwulan --"
              :options="$parent.triwulan" 
              :reduce="v=>String(v.value)" 
              @input="$parent.doFilter"
            />
          </b-col>
        </template>
        <template #mkrd_penurunan="data">
          {{data.scope.value + '%'}}
        </template>
        <template #mkrd_is_periode="data">
          <template v-if="data.scope.value == 'bulan'">
            {{ '2021-'+data.scope.item.mkrd_bulan+'-03' | moment("MMMM") }}
          </template>
          <template v-else>
            {{ 'Triwulan Ke-'+data.scope.item.mkrd_triwulan }}
          </template>
        </template>
        <template #mkrd_periode="data">
          {{ data.scope.item.mkrd_periode_mulai | moment("DD MMMM YYYY") }} - {{ data.scope.item.mkrd_periode_akhir| moment("DD MMMM YYYY") }}
        </template>
         <template #aksi="data">
           <b-button-group>
            <b-button
              variant="success"
              @click="doOpenModal('DetailModals', data.scope.item)"
            >
              <div v-b-tooltip.hover="'View Detail'">
                <b-icon icon="eye"/>
              </div>
            </b-button>

            <template v-if="!moduleRole('ViewOnly')">
              <b-button
                variant="warning"
                :to="{name:$route.name, params:{pageSlug:data.scope.item[$parent.idKey]}}"
              >
                <div v-b-tooltip.hover="'Edit'">
                  <b-icon icon="pencil-square" />
                </div>
              </b-button>
              <b-button
                variant="danger"
                @click="$parent.doDelete(data.scope.index, data.scope.item, {query: { periode }})"
              >
                <div v-b-tooltip.hover="'Hapus'">
                  <b-icon icon="trash" />
                </div>
              </b-button>
            </template>
          </b-button-group>
        </template>
    </card-list> 
  </b-card>
</div>
</template>
<script>
import CardList from '@/components/CardList'
import GlobalVue from '@/libs/Global'

export default {
  extends:GlobalVue,
  components: {
    CardList
  },
  props: {
    periode: String,
    risiko: [Object],
    loadingOverlayTb: Boolean
  },
  data(){
    return {
      fields:[
        { key:'number', label:'#', thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center'},
        { key:'mkr_name', label:'Risiko Korporat', thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center'},
        { key:'divisi', label:'Divisi', thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center'},
        { key:'mkrd_is_periode', label: this.periode == 'bulan' ? 'Bulan' : 'Triwulan' , thAttr: {rowspan: 1, colspan: 1}, thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center', is_custom: true},
        { key:'mkrd_sebab', label:'Sebab', thAttr: {rowspan: 1, colspan: 1}, thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center'},
        { key:'mkrd_dampak', label:'Dampak', thAttr: {rowspan: 1, colspan: 1}, thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center'},
        { key:'mkrd_sasaran_rtu', label:'Sasaran RTU', thAttr: {rowspan: 1, colspan: 1}, thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center'},
        /* { key:'mkrd_inheren_k', label:'K', thAttr: {rowspan: 1, colspan: 1}, thStyle: { minWidth: '50px' }, thClass: 'full-color-table full-danger-table text-center', tdClass: 'text-center'},
        { key:'mkrd_inheren_d', label:'D', thAttr: {rowspan: 1, colspan: 1}, thStyle: { minWidth: '50px' }, thClass: 'full-color-table full-danger-table text-center', tdClass: 'text-center'},
        { key:'mkrd_inheren_kd', label:'K x D', thAttr: {rowspan: 1, colspan: 1},thStyle: { minWidth: '100px' }, thClass: 'full-color-table full-danger-table text-center', tdClass: 'text-center' },
        { key:'mkrd_skor_efektivitas_k', label:'K', thAttr: {rowspan: 1, colspan: 1}, thStyle: { minWidth: '50px' }, thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center'},
        { key:'mkrd_skor_efektivitas_d', label:'D', thAttr: {rowspan: 1, colspan: 1}, thStyle: { minWidth: '50px' }, thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center' },
        { key:'mkrd_current_k', label:'K', thAttr: {rowspan: 1, colspan: 1}, thStyle: { minWidth: '50px' }, thClass: 'full-color-table full-warning-table text-center', tdClass: 'text-center'},
        { key:'mkrd_current_d', label:'D', thAttr: {rowspan: 1, colspan: 1}, thStyle: { minWidth: '50px' }, thClass: 'full-color-table full-warning-table text-center', tdClass: 'text-center' },
        { key:'mkrd_current_kd', label:'K x D', thAttr: {rowspan: 1, colspan: 1}, thStyle: { minWidth: '100px' }, thClass: 'full-color-table full-warning-table text-center', tdClass: 'text-center' }, */
        // { key:'mkrd_penurunan', label:'Penurunan', thAttr: {rowspan: 1, colspan: 1},thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center', is_custom: true },
        // { key:'mkrd_mitigasi_risiko', label:'Mitigiasi Risiko', thAttr: {rowspan: 1, colspan: 1}, thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center' },
        // { key:'mkrd_periode', label:'Periode', thAttr: {rowspan: 1, colspan: 1}, thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center', is_custom: true },
        // { key:'mp_description', label:'PIC', thAttr: {rowspan: 1, colspan: 1}, thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center' },
        /* { key:'mkrd_residual_k', label:'K', thAttr: {rowspan: 1, colspan: 1}, thStyle: { minWidth: '50px' }, thClass: 'full-color-table full-info-table text-center', tdClass: 'text-center' },
        { key:'mkrd_residual_d', label:'D', thAttr: {rowspan: 1, colspan: 1}, thStyle: { minWidth: '50px' }, thClass: 'full-color-table full-info-table text-center', tdClass: 'text-center' },
        { key:'mkrd_residual_kd', label:'K x D', thAttr: {rowspan: 1, colspan: 1}, thStyle: { minWidth: '100px' }, thClass: 'full-color-table full-info-table text-center', tdClass: 'text-center' },
        { key:'mkrd_pengendalian', label:'Pengendalian', thAttr: {rowspan: 1, colspan: 1}, thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center' },
        { key:'mkrd_proses_mitigasi', label:'Mitigasi', thAttr: {rowspan: 1, colspan: 1}, thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center'}, */
        { key:'aksi', label:'Aksi', thAttr: {rowspan: 1, colspan: 1} , thClass: 'full-color-table full-purple-table text-center', tdClass: 'text-center', is_custom: true},
      ],
    }
  },
  methods:{
    doOpenModal(id, row){
      this.$set(this.$parent, 'row', row)
      this.$bvModal.show(id)
    }
  }
}
</script>
<style>
.border-bot-none {
  border-bottom: none;
}
</style>