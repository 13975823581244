<template>
  <div class="container-fluid">
    <PageTitle/>

    <template v-if="isList">
      <div class="card">
          <ul class="nav nav-tabs customtab" role="tablist">
            <li class="nav-item">
              <a
                :class="$route.query.tab === 'bulan' ? 'active' : ''"
                class="nav-link finger"
                @click="filterPeriode('bulan')"
                role="tab"
                ><span class="hidden-sm-up"></span>
                <span class="hidden-xs-down">BULAN</span></a
              >
            </li>
            <li class="nav-item">
              <a
                :class="$route.query.tab === 'triwulan' ? 'active' : ''"
                class="nav-link finger"
                data-toggle="tab"
                @click="filterPeriode('triwulan')"
                ><span class="hidden-sm-up"></span>
                <span class="hidden-xs-down">TRIWULAN</span></a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane" :class="$route.query.tab == 'bulan' ? 'active' : ''">
              <TableRisikoKorporat periode="bulan" :risiko="data" :loadingOverlayTb="loadingOverlayTb"/>
            </div>

            <div class="tab-pane" :class="$route.query.tab == 'triwulan' ? 'active' : ''">
              <TableRisikoKorporat periode="triwulan" :risiko="data" :loadingOverlayTb="loadingOverlayTb"/>
            </div>
          </div>
      </div>
    </template>

    <div v-else class="card">
      <div class="card-body">
        <h5 class="card-title mb-1">
          {{ isAdd ? "Tambah" : "Ubah" }} {{ pageTitle }}
        </h5>

        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <b-row>
              <div class="col-md-12 mt-3 ">
                <div class="row">
                  <div class="col-sm-12 mt-3 mb-3">
                    <b-form-group>
                      <template #label>
                        Periode <span class="text-danger mr5">*</span>
                      </template>
                      <b-form-radio-group
                        id="radio-group-2"
                        v-model="row.mkrd_is_periode"
                        name="radio-group-2"
                      >
                        <b-form-radio value="bulan">Bulan</b-form-radio>
                        <b-form-radio value="triwulan" class="mr-5"
                          >Triwulan</b-form-radio
                        >
                      </b-form-radio-group>
                      <VValidate
                        name="Status"
                        v-model="row.mkrd_is_periode"
                        :rules="mrValidation.mkrd_is_periode"
                      />
                    </b-form-group>
                  </div>
                  <b-col md=8>
                    <b-form-group>
                      <template #label>
                        Risiko Korporat <span class="text-danger mr5">*</span>
                      </template>
                      <VSelect 
                        v-model="row.mkrd_risiko_id" 
                        placeholder="-- Pilih Satu --"
                        :options="mrRisikoKorporat" 
                        :reduce="v=>v.value" 
                      />
                      <VValidate 
                        name="Risiko Korporat" 
                        v-model="row.mkrd_risiko_id" 
                        rules="required" 
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md=8>
                    <b-form-group>
                      <template #label>
                        Divisi <span class="text-danger mr5">*</span>
                      </template>
                      <VSelect 
                        v-model="row.mkrd_divisi_id" 
                        placeholder="-- Pilih Satu --"
                        :options="mrDivisi" 
                        :reduce="v=>v.value" 
                      />
                      <VValidate 
                        name="Divisi" 
                        v-model="row.mkrd_divisi_id" 
                        rules="required" 
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md=6>
                    <template v-if="row.mkrd_is_periode == 'triwulan'">
                      <b-form-group>
                        <template #label>
                          Triwulan <span class="text-danger mr5">*</span>
                        </template>
                        <VSelect
                          v-model="row.mkrd_triwulan"
                          placeholder="-- Pilih Satu --"
                          :options="triwulan"
                          :reduce="(v) => v.value"
                        />
                        <VValidate
                          name="Triwulan"
                          v-model="row.mkrd_triwulan"
                          rules="required"
                        />
                      </b-form-group>
                    </template>
                    <template v-else>
                      <b-form-group>
                        <template #label>
                          Bulan <span class="text-danger mr5">*</span>
                        </template>
                        <VSelect
                          v-model="row.mkrd_bulan"
                          placeholder="-- Pilih Satu --"
                          :options="bulan"
                          :reduce="(v) => String(v.value)"
                        />
                        <VValidate
                          name="Bulan"
                          v-model="row.mkrd_bulan"
                          :rules="mrValidation.mkrd_bulan"
                        />
                      </b-form-group>
                    </template>
                  </b-col>
                  <b-col md=6>
                    <b-form-group>
                      <template #label>
                        Sasaran RTU <span class="text-danger mr5">*</span>
                      </template>
                      <b-input
                        v-model="row.mkrd_sasaran_rtu"
                      ></b-input>
                      <VValidate
                        name="Sasaran RTU"
                        v-model="row.mkrd_sasaran_rtu"
                        :rules="mrValidation.mkrd_sasaran_rtu"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md=6>
                    <b-form-group>
                      <template #label>
                        Sebab <span class="text-danger mr5">*</span>
                      </template>
                      <b-form-textarea
                        v-model="row.mkrd_sebab"
                        rows="5"
                      ></b-form-textarea>
                      <VValidate
                        name="Sebab"
                        v-model="row.mkrd_sebab"
                        :rules="mrValidation.mkrd_sebab"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md=6>
                    <b-form-group>
                      <template #label>
                        Dampak <span class="text-danger mr5">*</span>
                      </template>
                      <b-form-textarea
                        v-model="row.mkrd_dampak"
                        rows="5"
                      ></b-form-textarea>
                      <VValidate
                        name="Dampak"
                        v-model="row.mkrd_dampak"
                        :rules="mrValidation.mkrd_dampak"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md=12>
                    <div class="inbox-center">
                      <table
                        class="table m-b-0 table-bordered"
                      >
                        <thead>
                          <tr class="text-center ">
                            <th
                              colspan="3"
                              class="full-color-table full-danger-table text-center full-color-table full-danger-table"
                            >
                              Inheren
                            </th>
                            <th
                              class="full-color-table full-purple-table v_middle"
                              colspan="2"
                            >
                              Skor Efektivitas
                            </th>
                          </tr>
                          <tr>
                            <th
                              rowspan="1"
                              colspan="1"
                              class="text-center full-color-table full-danger-table"
                            >
                              K
                            </th>
                            <th
                              rowspan="1"
                              colspan="1"
                              class="text-center full-color-table full-danger-table"
                            >
                              D
                            </th>
                            <th
                              rowspan="1"
                              colspan="1"
                              class="text-center full-color-table full-danger-table"
                            >
                              K x D
                            </th>
                            <th
                              rowspan="1"
                              colspan="1"
                              class="text-center full-color-table full-purple-table"
                            >
                              K
                            </th>
                            <th
                              rowspan="1"
                              colspan="1"
                              class="text-center full-color-table full-purple-table"
                            >
                              D
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="un read">
                            <td class="hidden-xs-down">
                              <b-form-group>
                                <VSelect
                                  v-model="row.mkrd_inheren_k"
                                  :options="nilai"
                                  :reduce="(v) => v.value"
                                  class="vtable"
                                />
                                <br />
                                <VValidate
                                  name="Inheren K"
                                  v-model="row.mkrd_inheren_k"
                                  :rules="mrValidation.mkrd_inheren_k"
                                />
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group>
                                <VSelect
                                  v-model="row.mkrd_inheren_d"
                                  :options="nilai"
                                  :reduce="(v) => v.value"
                                  class="vtable"
                                />
                                <br />
                                <VValidate
                                  name="Inheren D"
                                  v-model="row.mkrd_inheren_d"
                                  :rules="mrValidation.mkrd_inheren_d"
                                />
                              </b-form-group>
                            </td>
                            <td>
                              <b-input
                                type="text"
                                v-model="inherenKD"
                                readonly
                              />
                              <br />
                            </td>
                            <td>
                              <VSelect 
                                v-model="row.mkrd_skor_efektivitas_k" 
                                placeholder="-- Pilih Satu --"
                                :options="skorEfektivitas" 
                                :reduce="v=>v.value" 
                              />
                              <br />
                              <VValidate
                                name="Skor Efektivitas K"
                                v-model="row.mkrd_skor_efektivitas_k"
                                :rules="mrValidation.mkrd_skor_efektivitas_k"
                              />
                            </td>
                            <td>
                              <VSelect 
                                v-model="row.mkrd_skor_efektivitas_d" 
                                placeholder="-- Pilih Satu --"
                                :options="skorEfektivitas" 
                                :reduce="v=>v.value" 
                              />
                              <br />
                              <VValidate
                                name="Skor Efektivitas D"
                                v-model="row.mkrd_skor_efektivitas_d"
                                :rules="mrValidation.mkrd_skor_efektivitas_d"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                  <b-col md=12 class="mt-2">
                    <div class="inbox-center">
                      <table
                        class="table m-b-0 table-bordered"
                      >
                        <thead>
                          <tr class="text-center ">
                            <th
                              colspan="3"
                              class="full-color-table full-warning-table text-center"
                            >
                              Current
                            </th>
                            <th
                              class="full-color-table full-purple-table v_middle"
                              rowspan="2"
                            >
                              Penurunan (%)
                            </th>
                          </tr>
                          <tr>
                            <th
                              rowspan="1"
                              colspan="1"
                              class="text-center full-color-table full-warning-table"
                            >
                              K
                            </th>
                            <th
                              rowspan="1"
                              colspan="1"
                              class="text-center full-color-table full-warning-table"
                            >
                              D
                            </th>
                            <th
                              rowspan="1"
                              colspan="1"
                              class="text-center full-color-table full-warning-table"
                            >
                              K x D
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="un read">
                            <td>
                              <b-input
                                type="text"
                                v-model="row.mkrd_current_k"
                                readonly
                              />
                              <br />
                              <VValidate
                                name="Current K"
                                v-model="row.mkrd_current_k"
                                :rules="mrValidation.mkrd_current_k"
                              />
                            </td>
                            <td>
                              <b-input
                                type="text"
                                v-model="row.mkrd_current_d"
                                readonly
                              />
                              <br />
                              <VValidate
                                name="Current D"
                                v-model="row.mkrd_current_d"
                                :rules="mrValidation.mkrd_current_d"
                              />
                            </td>
                            <td>
                              <b-input
                                type="text"
                                v-model="row.mkrd_current_kd"
                                readonly
                              />
                            </td>
                            <td>
                              <div
                                class="btn-group m-r-10"
                                role="group"
                                aria-label="Button group with nested dropdown"
                              >
                                <div class="input-group">
                                  <b-input
                                    type="text"
                                    v-model="row.mkrd_penurunan"
                                    readonly
                                  />
                                  <div class="input-group-prepend">
                                    <div
                                      class="input-group-text"
                                      id="btnGroupAddon"
                                    >
                                      %
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <VValidate
                                name="Penurunan"
                                v-model="row.mkrd_penurunan"
                                :rules="mrValidation.mkrd_penurunan"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                  <b-col md=6 class="mt-3">
                    <b-form-group>
                      <template #label>
                        Mitigasi Risiko <!-- <span class="text-danger mr5">*</span> -->
                      </template>
                      <b-textarea
                        v-model="row.mkrd_mitigasi_risiko"
                        rows="4"
                      ></b-textarea>
                      <VValidate
                        name="Mitigasi Risiko"
                        v-model="row.mkrd_mitigasi_risiko"
                        :rules="mrValidation.mkrd_mitigasi_risiko"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md=6 class="mt-3">
                    <b-form-group>
                      <template #label>
                        Pengendalian <span class="text-danger mr5">*</span>
                      </template>
                      <b-textarea
                        v-model="row.mkrd_pengendalian"
                        rows="4"
                      ></b-textarea>
                      <VValidate
                        name="Pengendalian"
                        v-model="row.mkrd_pengendalian"
                        :rules="mrValidation.mkrd_pengendalian"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md=4>
                    <b-form-group>
                      <template #label>
                        Periode <span class="text-danger mr5">*</span>
                      </template>
                      <DatePicker
                        v-model="row.mkrd_mitigasi_periode"
                        type="date"
                        range
                        format="DD-MM-YYYY"
                        value-type="YYYY-MM-DD"
                        style="min-width: 100%;"
                        input-class="form-control"
                        placeholder="Pilih Tanggal"
                      />
                      <VValidate
                        name="Tgl Periode"
                        v-model="row.mkrd_mitigasi_periode"
                        rules="required"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md=6>
                    <b-form-group>
                    <template #label>
                      PIC <span class="text-danger mr5">*</span>
                    </template>
                    <VSelect 
                      v-model="row.mkrd_pic" 
                      placeholder="-- Pilih Satu --"
                      :options="mrPic" 
                      :reduce="v=>v.value" 
                    />
                    <VValidate 
                      name="Kategori" 
                      v-model="row.mkrd_pic" 
                      :rules="mrValidation.mkrd_pic" 
                    />
                  </b-form-group>
                  </b-col>
                  <b-col md=12 class="mt-2">
                    <div class="inbox-center">
                      <table
                        class="table m-b-0 table-bordered"
                      >
                        <thead>
                          <tr class="text-center ">
                            <th
                              colspan="3"
                              class="full-color-table full-info-table text-center"
                            >
                              Residual
                            </th>
                          </tr>
                          <tr>
                            <th
                              rowspan="1"
                              colspan="1"
                              class="text-center full-color-table full-info-table"
                            >
                              K
                            </th>
                            <th
                              rowspan="1"
                              colspan="1"
                              class="text-center full-color-table full-info-table"
                            >
                              D
                            </th>
                            <th
                              rowspan="1"
                              colspan="1"
                              class="text-center full-color-table full-info-table"
                            >
                              K x D
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="un read">
                            <td>
                              <b-input
                                type="text"
                                v-model="row.mkrd_residual_k"
                              />
                              <br />
                              <VValidate
                                name="Residual K"
                                v-model="row.mkrd_residual_k"
                                :rules="mrValidation.mkrd_residual_k"
                              />
                            </td>
                            <td>
                               <b-input
                                type="text"
                                v-model="row.mkrd_residual_d"
                              />
                              <br />
                              <VValidate
                                name="Residual D"
                                v-model="row.mkrd_residual_d"
                                :rules="mrValidation.mkrd_residual_d"
                              />
                            </td>
                            <td>
                              <b-input
                                type="text"
                                v-model="row.mkrd_residual_kd"
                                readonly
                              />
                              <br />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                  <b-col md=8 class="mt-4">
                    <b-form-group>
                      <template #label>
                        Progress Mitigasi <!-- <span class="text-danger mr5">*</span> -->
                      </template>
                      <b-form-textarea
                        id="textarea"
                        v-model="row.mkrd_proses_mitigasi"
                        rows="3"
                      ></b-form-textarea>
                      <VValidate
                        name="Progress Mitigasi"
                        v-model="row.mkrd_proses_mitigasi"
                        :rules="mrValidation.mkrd_proses_mitigasi"
                      />
                    </b-form-group>
                  </b-col>
                </div>
              </div>
            </b-row>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <button
                  type="submit"
                  class="btn btn-block btn-rounded  btn-info"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <b-modal 
      id="DetailModals" 
      title="Detail Risiko Korporat"
      size="lg"
      :busy="loadingOverlay"
      :hide-footer="true"
    >
      <div v-if="Object.keys(row).length">
        <table class="table table-bordered">
          <tr>
            <th>
              <b>Risiko Korporat :</b>
            </th>
            <td>
              {{ row.mkr_name }}
            </td>
          </tr>
          <tr>
            <th>
              <b>Divisi :</b>
            </th>
            <td>
              {{ row.divisi||'-' }}
            </td>
          </tr>
          <tr>
            <th>
              <b>{{ $route.query.tab=='triwulan'?'Triwulan':'Bulan' }} :</b>
            </th>
            <td>
              <template v-if="row.mkrd_is_periode == 'bulan'">
                {{ '2021-'+row.mkrd_bulan+'-03' | moment("MMMM") }}
              </template>
              <template v-else>
                {{ 'Triwulan Ke-'+row.mkrd_triwulan }}
              </template>
            </td>
          </tr>          
          <tr>
            <th>
              <b>Periode :</b>
            </th>
            <td>
              {{ row.mkrd_periode_mulai | moment("DD MMMM YYYY") }} - {{ row.mkrd_periode_akhir| moment("DD MMMM YYYY") }}
            </td>
          </tr>
          <tr>
            <th>
              <b>Sebab :</b>
            </th>
            <td style="white-space: pre-line">
              {{ row.mkrd_sebab }}
            </td>
          </tr>
          <tr>
            <th>
              <b>Dampak :</b>
            </th>
            <td style="white-space: pre-line">
              {{ row.mkrd_dampak }}
            </td>
          </tr>
          <tr>
            <th>
              <b>Sasaran RTU :</b>
            </th>
            <td>
              {{ row.mkrd_sasaran_rtu }}
            </td>
          </tr>
          <tr>
            <th>
              <b>Inheren :</b>
            </th>
            <td>
              <ul class="ml-2">
                <li>K : {{ row.mkrd_inheren_k||0 }}</li>
                <li>D : {{ row.mkrd_inheren_d||0 }}</li>
                <li>KxD : {{ row.mkrd_inheren_kd||0 }}</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>
              <b>Skor Efektifitas :</b>
            </th>
            <td>
              <ul class="ml-2">
                <li>K : {{ row.mkrd_skor_efektivitas_k||0 }}</li>
                <li>D : {{ row.mkrd_skor_efektivitas_d||0 }}</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>
              <b>Current :</b>
            </th>
            <td>
              <ul class="ml-2">
                <li>K : {{ row.mkrd_current_k||0 }}</li>
                <li>D : {{ row.mkrd_current_d||0 }}</li>
                <li>KxD : {{ row.mkrd_current_kd||0 }}</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>
              <b>Penurunan :</b>
            </th>
            <td style="white-space: pre-line">
              {{ row.mkrd_penurunan }}
            </td>
          </tr>
          <tr>
            <th>
              <b>Mitigasi Risiko :</b>
            </th>
            <td style="white-space: pre-line">
              {{ row.mkrd_mitigasi_risiko }}
            </td>
          </tr>
          <tr>
            <th>
              <b>PIC :</b>
            </th>
            <td style="white-space: pre-line">
              {{ row.mp_description }}
            </td>
          </tr>
          <tr>
            <th>
              <b>Residual :</b>
            </th>
            <td>
              <ul class="ml-2">
                <li>K : {{ row.mkrd_residual_k||0 }}</li>
                <li>D : {{ row.mkrd_residual_d||0 }}</li>
                <li>KxD : {{ row.mkrd_residual_kd||0 }}</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>
              <b>Pengendalian :</b>
            </th>
            <td style="white-space: pre-line">
              {{ row.mkrd_pengendalian }}
            </td>
          </tr>
          <tr>
            <th>
              <b>Mitigasi :</b>
            </th>
            <td style="white-space: pre-line">
              {{ row.mkrd_proses_mitigasi }}
            </td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";
import TableRisikoKorporat from "@view/include/TableRisikoKorporat";
import Gen from '@/libs/Gen'

export default {
  extends: GlobalVue,
  components: {
    PageTitle,
    TableRisikoKorporat
  },
  data() {
    return {
      idKey: "mkrd_id",
      bulan: [],
      triwulan: [],
      nilai: [],
      tab: "bulan",
      detailRisiko: {},
      mrPic: [],
      skorEfektivitas: [],
      opsiResidualK: [],
      opsiResidualD: [],
      mrRisikoKorporat: [],
      mrDivisi: [],
    };
  },
  mounted() {
    if(!this.$route.query.tab && !this.$route.params.pageSlug){
      this.$router.push({query: {tab: 'bulan'}})
    }
    this.apiGet({
      query: {
        periode: this.$route.query.tab,
      },
    });
  },
  watch: {
    $route() {
      if(!this.$route.query.tab && !this.$route.params.pageSlug){
      this.$router.push({query: {tab: 'bulan'}})
    }
      this.apiGet({
        query: {
          risikoKorporat: this.$route.params.risikoKorporat,
          periode: this.$route.query.tab,
        },
      });
    },
    inherenKD(newVal) {
      this.row.mkrd_inheren_kd = newVal;
      if(this.inherenKD > 0 && this.row.mkrd_current_kd > 0){
        this.setPenurunan();
      }else {
        this.row.mkrd_penurunan = 0
      }
    },
    "row.mkrd_current_kd"(newVal) {
      this.row.mkrd_current_kd = newVal;
      if(this.inherenKD > 0 && this.row.mkrd_current_kd > 0){
       this.setPenurunan();
      }else {
        this.row.mkrd_penurunan = 0
      }
    },
    "row.mkrd_inheren_k"(){
      this.setCurrentK();
      this.setPenurunan();
    },
    "row.mkrd_inheren_d"(){
      this.setCurrentD();
      this.setPenurunan()
    },
    "row.mkrd_skor_efektivitas_k"(){
      this.setCurrentK()
      this.setPenurunan()
    },
    "row.mkrd_skor_efektivitas_d"(){
      this.setCurrentD()
      this.setPenurunan()
    },
    "row.mkrd_current_k"(){
      if(parseInt(this.row.mkrd_residual_k) > parseInt(Math.ceil(this.row.mkrd_current_k))){
        this.$set(this.row, 'mkrd_residual_k', null)
      }
      this.setOpsiResidualK()
    },
    "row.mkrd_current_d"(){
      if(parseInt(this.row.mkrd_residual_d) > parseInt(Math.ceil(this.row.mkrd_current_d))){
        this.$set(this.row, 'mkrd_residual_d', null)
      }
      this.setOpsiResidualD()
    },
    "row.mkrd_residual_k"(){
      this.setResidualKD();
    },
    "row.mkrd_residual_d"(){
      this.setResidualKD()
    }
  },
  methods: {
    filterPeriode(periode) {
      this.loadingOverlayTb = true
      this.$set(this,'data',{data:false})
      this.$emit('update:filter',{})
      this.$router.push({name:this.$route.name, query: { tab: periode }});

      this.apiGet({
        query: {
          risikoKorporat: this.$route.params.risikoKorporat,
          periode: this.$route.query.tab,
        },
      });
      this.loadingOverlayTb = false;
    },
    setCurrentD(){
      const currentD = (this.row.mkrd_inheren_d || 0) * (this.row.mkrd_skor_efektivitas_d || 0)
      this.$set(this.row, 'mkrd_current_d', currentD.toFixed(2));

      this.setCurrentKD();
    },
    setCurrentK(){
      const currentK  = (this.row.mkrd_inheren_k || 0) * (this.row.mkrd_skor_efektivitas_k || 0)
      this.$set(this.row, 'mkrd_current_k', currentK.toFixed(2))

      this.setCurrentKD();
    },
    setCurrentKD(){
      const valueCurrK = this.row.mkrd_current_k || 0;
      const valueCurrD = this.row.mkrd_current_d || 0;

      const result = (valueCurrK * valueCurrD).toFixed(2);
      this.$set(this.row, 'mkrd_current_kd', result);
    },
    setPenurunan(){
      const penurunan = ((this.inherenKD - this.row.mkrd_current_kd) / this.inherenKD) * 100
      this.row.mkrd_penurunan = Math.round(penurunan || 0)
    },
    setResidualKD(){
      let residualKD = this.row.mkrd_residual_k * this.row.mkrd_residual_d;
      this.row.mkrd_residual_kd = residualKD;
    },
    setOpsiResidualK(){
      this.$set(this.row, 'mkrd_residual_k',  this.row.mkrd_current_k)
    },
    setOpsiResidualD(){
      this.$set(this.row, 'mkrd_residual_d',  this.row.mkrd_current_d)
    }
  },
  computed: {
    baseUrl(){
      return Gen.baseUrl()
    },
    inherenKD: {
      get() {
        return (this.row.mkrd_inheren_k || 0) * (this.row.mkrd_inheren_d || 0);
      },
      set(newValue) {
        this.row.mkrd_inheren_kd = newValue;
      },
    },
  },
};
</script>
<style scoped>
th {
  width: 20%;
}
</style>
<style>
.vtable .vs__search {
  width: 100%;
}
</style>
